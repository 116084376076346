import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  CardOptionsInfo,
  ControlledModal,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import {
  NextIcon,
  PlusIcon,
  PreviousIcon,
  RefreshIcon,
} from '@/components/icons';
import { useTranslate } from '@/contexts';
import PlayerCreateAdjustmentForm from '@/forms/player-create-adjustment-form/PlayerCreateAdjustmentForm';
import {
  AdjustmentCategory,
  TransactionSearchOrder,
  TransactionType,
} from '@/globalTypes';
import { assert } from '@/utils/error';
import { formatCurrencyAmountRecord } from '@/utils/stats-with-currency';
import { SanityPlayerAdjustmentsBlockFragment } from './__generated__/SanityPlayerAdjustmentsBlockFragment';
import usePlayerAdjustments from './usePlayerAdjustments';

export const Fragment = graphql`
  fragment SanityPlayerAdjustmentsBlockFragment on SanityPlayerAdjustmentsBlock {
    title {
      ...LocaleString
    }
    ngr {
      ...LocaleString
    }
    totalAdjustments {
      ...LocaleString
    }
    adjustments {
      ...LocaleString
    }
    bonusAdjustments {
      ...LocaleString
    }
  }
`;

const PlayerAdjustmentsBlock: FC<{
  block: SanityPlayerAdjustmentsBlockFragment;
}> = ({ block }) => {
  const params = useParams();
  const { t } = useTranslate();

  assert(params.playerId, 'missing playerId');

  const {
    adjustments,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
    adjustmentSummaries,
  } = usePlayerAdjustments(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerAdjustments',
    data: adjustments,
    fetching,
    orderBy,
    setOrderBy,
    desc,
    defaultFilter,
    updateFilter,
    schema: (s) => [
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Time',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: TransactionSearchOrder.processedAt,
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.enumValue({
        title: 'Adjustment Category',
        field: 'adjustmentType.category',
        filterField: 'adjustmentCategory',
        getValue: ({ row }) => row.adjustmentType?.category,
        e: AdjustmentCategory,
      }),
      s.enumValue({
        field: 'transactionType',
        title: 'Transaction Type',
        e: TransactionType,
        filterField: 'transactionTypes',
      }),
      s.monetaryValue({
        field: 'amount',
        title: 'Amount',
        fromFilterField: 'minAmount',
        toFilterField: 'maxAmount',
        orderBy: TransactionSearchOrder.amount,
      }),
      s.stringValue({
        field: 'comment',
        title: 'Comment',
      }),
    ],
  });

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsInfo>
            <div className="flex space-x-2 px-2">
              {adjustmentSummaries.ngr != null && (
                <div>
                  {t(block.ngr, {
                    amount: formatCurrencyAmountRecord(adjustmentSummaries.ngr),
                  })}
                </div>
              )}

              {adjustmentSummaries.adjustments != null && (
                <div>
                  {t(block.adjustments, {
                    amount: formatCurrencyAmountRecord(
                      adjustmentSummaries.adjustments,
                    ),
                  })}
                </div>
              )}
              {adjustmentSummaries.bonusAdjustments != null && (
                <div>
                  {t(block.bonusAdjustments, {
                    amount: formatCurrencyAmountRecord(
                      adjustmentSummaries.bonusAdjustments,
                    ),
                  })}
                </div>
              )}
              {adjustmentSummaries.combinedAdjustments != null && (
                <div>
                  {t(block.totalAdjustments, {
                    amount: formatCurrencyAmountRecord(
                      adjustmentSummaries.combinedAdjustments,
                    ),
                  })}
                </div>
              )}
            </div>
          </CardOptionsInfo>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
          <ControlledModal
            content={<PlayerCreateAdjustmentForm playerId={params.playerId} />}
          >
            <CardOptionsButton>
              <PlusIcon />
            </CardOptionsButton>
          </ControlledModal>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerAdjustmentsBlock;
