import { isNumeric } from './is-numeric';

export function toNumber(value: unknown) {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    const normalized = value.replace(',', '.').trim();

    if (isNumeric(normalized)) {
      return Number(normalized);
    }
  }

  return undefined;
}
