export function isNumeric(value: unknown) {
  if (typeof value === 'number') {
    return true;
  }

  if (typeof value === 'string') {
    const casted = Number(value);
    return value === String(casted);
  }

  return false;
}
